/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="startExport()"
          >Exportar pagos de ventas</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de clientes
      <b>{{ clientsAccounts.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="clientsAccounts"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedIncomes"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NOMBRE}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="NOMBRE"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NOMBRE"
      >
        <template #empty>
          <div class="has-text-centered">No hay clientes</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
            :cell-class="
              props.row.DIAS_VEN > 0 && props.row.STATUS !== 'PA'
                ? 'red-cell'
                : ''
            "
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <div class="content">
                <p>No hay información que mostrar</p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ClientsBalanceInvoices",
  props: [],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedIncomes: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NOMBRE",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RESTA_TOTAL",
          label: "Restante a pagar total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingQuantity,
        },
        {
          field: "FACTURAS",
          label: "Facturas",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
      filterClient: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      allPromises.push(
        this.$store.dispatch("GETINCOMES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.RESTA_TOTAL) - Number(b.RESTA_TOTAL);
      } else {
        return Number(b.RESTA_TOTAL) - Number(a.RESTA_TOTAL);
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación",
        message: `¿Desea confirmar la exportación de los estados de cuenta a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: async () => {
          this.exportData();
        },
      });
    },
    exportData() {
      let payload = {
        data: this.clientsAccounts.map((singleBalance) => {
          const singleBalanceFormatted = {};

          singleBalanceFormatted["CLIENTE"] = "";
          if (singleBalance.NOMBRE) {
            singleBalanceFormatted["CLIENTE"] = singleBalance.NOMBRE;
          }

          singleBalanceFormatted["RESTANTE A PAGAR"] = 0;
          singleBalanceFormatted["RESTANTE A PAGAR"] =
            singleBalance.RESTA_TOTAL;

          singleBalanceFormatted["FACTURAS"] = "";
          singleBalanceFormatted["FACTURAS"] = singleBalance.FACTURAS;

          return singleBalanceFormatted;
        }),
        name: "estados-de-cuenta-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };

        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    clientsAccounts() {
      let clientsAccounts = {};

      for (const singleIncome of this.$store.getters.INCOMES) {
        if (
          singleIncome.TIPO_ING === "V" &&
          singleIncome.CLAVE_VENTA &&
          singleIncome.CLAVE_VENTA.STATUS_FAC === "TI"
        ) {
          if (!clientsAccounts[singleIncome.CLAVE_CLIENTE._id]) {
            clientsAccounts[singleIncome.CLAVE_CLIENTE._id] = {
              NOMBRE: singleIncome.CLAVE_CLIENTE.NOMBRE_EMPRESA,
              RESTA_TOTAL: Number(singleIncome.RESTA),
              FACTURAS: "",
            };
          } else {
            clientsAccounts[singleIncome.CLAVE_CLIENTE._id].RESTA_TOTAL +=
              Number(singleIncome.RESTA);
          }

          if (
            singleIncome.CLAVE_VENTA &&
            singleIncome.CLAVE_VENTA.FACTURAS &&
            singleIncome.CLAVE_VENTA.FACTURAS.length > 0
          ) {
            for (const singleInvoice of singleIncome.CLAVE_VENTA.FACTURAS) {
              clientsAccounts[
                singleIncome.CLAVE_CLIENTE._id
              ].FACTURAS += `${singleInvoice.CLAVE_FACTURA} `;
            }
          }
        }
      }

      return Object.values(clientsAccounts);
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
